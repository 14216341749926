import { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import QuestionEditor from './QuestionEditor';

const QuestionsEditor = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [questionId, setQuestionId] = useState('');
  const [questionSetId, setQuestionSetId] = useState('');
  
  useEffect(() => {
    const questionId = searchParams.get('id');
    setQuestionId(questionId);
    const questionSetId = searchParams.get('eqsId');
    setQuestionSetId(questionSetId);
  }, [searchParams]);
  
  return (
    <QuestionEditor questionId={questionId} questionSetId={questionSetId} />
  );
};
  
export default QuestionsEditor;