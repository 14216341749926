import { useState, useRef, useEffect } from 'react';
import reactStringReplace from 'react-string-replace';
import * as htmlToImage from 'html-to-image';
import { BeatLoader } from 'react-spinners';

import classNames from 'classnames';
import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';

import PopupContainer from './PopupContainer';
import Comprehension from './Comprehension';

import { 
    getComprehensionsAPI,
    saveQuestionComprehensionAPI
} from '../apis/ExamsApis';

const QuestionComprehension = ({ question, onChange }) => {
  const [comprehensions, setComprehensions] = useState([]);
  const [comprehensionId, setComprehensionId] = useState(question.comprehension_id);
  const [comprehensionTitle, setComprehensionTitle] = useState(question.comprehension_title);
  const [loadComplete, setLoadComplete] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  
  
  useEffect(() => {
    if(question.exam_section_id) {
      if(question.book_id) {
        fetchComprehensions(question.exam_section_id, question.book_id)
      } else {
        setComprehensions([]);
      }
    } else {
      setComprehensions([]);
    }
  }, [question.exam_section_id])
  
  function fetchComprehensions(examSectionId, bookId) {
    getComprehensionsAPI(examSectionId, bookId).then((response) => {
      if(response.status == 'ok') {
        setComprehensions(response.data);
        setLoadComplete(true);
      }
    });
  }
  
  function saveQuestionComprehensionId(comprehensionId) {
    setIsSaving(true);
    const data = { comprehension_id: comprehensionId };
    saveQuestionComprehensionAPI(question.id, data).then((response) => {
      if(response.status == 'ok') {
        onChange(comprehensionId, comprehensionTitle);
        setIsSaving(false);
        setIsEdited(false);
      }
    });
  }
  
  function onClose() {
    setComprehensionId(question.comprehension_id);
    setComprehensionTitle(question.comprehension_title);
    setShowEditor(false);
  }
  
  function onSaveComprehension(id, title) {
    setComprehensionTitle(title);
    if (comprehensionId == id) {
      setComprehensions(items => items.map(item => { return item.id == id ?  {...item, title} : item }));
    } else {
      const newItem = { 'id': id, 'title': title };
      setComprehensions(items => [...items, newItem ]);
      setComprehensionId(id);
      saveQuestionComprehensionId(id);
    }
  }
  
  function onChangeComprehension(id, title) {
    setComprehensionTitle(title);
    setComprehensionId(id);
    saveQuestionComprehensionId(id);
  }
    
  function onNewPassageButtonClick() {
    setComprehensionTitle('');
    setComprehensionId('');
    setShowEditor(true);
  }
    
  function onEditPassageButtonClick() {
    setShowEditor(true);
  }
    
  return (
    <div 
      className='seg'
      style={{ minHeight: 'initial' }}
    >
      <div 
        className={classNames('seg__left')}
        style={{ width: 'initial' }}
      >
        <div 
          className={classNames('seg__left__view')}
          style={{ flexDirection: 'row' }}
        >
          <select
            value={question.comprehension_id}
            onChange={(e) => onChangeComprehension(e.target.value, e.target.title)}
            style={{ minWidth: '300px' }}
          >
          <option key={'comprehension_select'} value={''}>Select</option>
          { comprehensions.map(c => <option key={c.id} title={c.title} value={c.id}>{c.title}</option>) }
          </select>
          <div className={classNames('btn-holder')}>
            { comprehensionId && <button onClick={onEditPassageButtonClick} disabled={ isSaving ? true : false}>View / Edit</button> }
            <button onClick={onNewPassageButtonClick} disabled={ isSaving ? true : false}>New Passage</button>
          </div>
        </div>
      </div>
    
      <PopupContainer
        visible={showEditor}
        onClose={() => {
          onClose();
        }}
        content={ 
          <Comprehension 
            comprehensionId={comprehensionId}
            comprehensionTitle={comprehensionTitle}
            examSectionId={question.exam_section_id}
            bookId={question.book_id}
            onSave={onSaveComprehension}
            onClose={() => { onClose() }}
          />
        }
      />
    </div>
  );
};

export default QuestionComprehension;
