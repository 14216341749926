import { useState, useRef, useEffect } from 'react';
import { BeatLoader } from 'react-spinners';

import classNames from 'classnames';
import 'katex/dist/katex.min.css';

import { 
    saveQuestionAnswerAPI,
} from '../apis/ExamsApis';

const Answer = ({ questionId, question, onSave}) => {
  const [answer, setAnswer] = useState(question.answer);
  const [isEdited, setIsEdited] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  function save() {
    setIsSaving(true);
    const data = { answer: answer };
    saveQuestionAnswerAPI(questionId, data).then((response) => {
      if(response.status == 'ok') {
        onSave(answer);
        setIsSaving(false);
        setIsEdited(false);
      }
    });
  }
  
  function onCancel() {
    setAnswer(question.answer);
    setIsEdited(false);
  }
  
  function onChangeAnswer(value) {
    setAnswer(value);
    setIsEdited(value != question.answer);
  }
  
  return (
    <div 
      className='seg'
      style={{ minHeight: 'initial' }}
    >
      <div 
        className={classNames('seg__left')}
      >
        <div className={classNames('seg__left__view')}>
          <textarea
            value={ answer }
            onChange={ (e) => onChangeAnswer(e.target.value) }
          />
        </div>
      </div>
      <div 
        className={classNames('seg__right')}
        style={{ paddingTop: '5px' }}
      >
        <div>
          <button onClick={() => save()} disabled={ isSaving || !isEdited ? true : false}>Save</button>
        </div>
        { isEdited && <div>
            <button onClick={() => onCancel()} disabled={ isSaving ? true : false}>Cancel</button>
          </div>
        }
        { isSaving && <div>Saving <BeatLoader 
            color="#22AA22"
            loading={true}
            size={5}
            aria-label="Loading Spinner"
            data-testid="loader"
          /> Please wait!</div>
        }
      </div>
    </div>
  );
};

export default Answer;
