 import { useState, useRef, useEffect } from 'react';
import reactStringReplace from 'react-string-replace';
import * as htmlToImage from 'html-to-image';
import { BeatLoader } from 'react-spinners';

import classNames from 'classnames';
import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';

import { 
    saveQuestionOptionsAPI,
} from '../apis/ExamsApis';

const Options = ({ questionId, question, onSave, svg_store}) => {
  const [currentView, setCurrentView] = useState('text');
  const [options, setOptions] = useState({ 
    option_a: question.option_a, 
    option_b: question.option_b, 
    option_c: question.option_c, 
    option_d: question.option_d, 
    option_e: question.option_e, 
  });
  const [isEdited, setIsEdited] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [saveImageRequired, setSaveImageRequired] = useState(false);
  const [imageRefreshRandomNumber, setImageRefreshRandomNumber] = useState(Math.random());

  const imageRef = useRef(null);
  
  console.log('saveImageRequired:', saveImageRequired)
  
  useEffect(() => {
    if(question.options_image) {
      setCurrentView('image');
    } else {
      setSaveImageRequired(checkSaveImageRequired(options));
      setCurrentView('text');
    }
  }, [question.options_image])
  
  function saveAsText() {
    setIsSaving(true);
    const data = { ...options };
    saveQuestionOptionsAPI(questionId, data).then((response) => {
      if(response.status == 'ok') {
        setIsSaving(false);
        onSave(
          options.option_a, 
          options.option_b, 
          options.option_c, 
          options.option_d, 
          options.option_e, 
          ""
        );
        setIsEdited(false);
      }
    });
  }
  
  async function saveAsImage() {
    setIsSaving(true);
    const imageData = await htmlToImage.toPng(imageRef.current);
    const data = { ...options, image_data: imageData };
    saveQuestionOptionsAPI(questionId, data).then((response) => {
      if(response.status == 'ok') {
        setIsSaving(false);
        setCurrentView('image');
        onSave(
          options.option_a, 
          options.option_b, 
          options.option_c, 
          options.option_d, 
          options.option_e, 
          response.data.options_image
        );
        setIsEdited(false);
        setSaveImageRequired(false);
        setImageRefreshRandomNumber(Math.random());
      }
    });
  }
  
  function onCancel() {
    setOptions({ 
      option_a: question.option_a, 
      option_b: question.option_b, 
      option_c: question.option_c, 
      option_d: question.option_d, 
      option_e: question.option_e, 
    });
    setIsEdited(false);
  }
  
  function checkIfEdited(options) {
    if(options.option_a != question.option_a) {
      return true;
    }
    if(options.option_b != question.option_b) {
      return true;
    }
    if(options.option_c != question.option_c) {
      return true;
    }
    if(options.option_d != question.option_d) {
      return true;
    }
    if(options.option_e != question.option_e) {
      return true;
    }
    return false;
  }
  
  function checkSaveImageRequired(options) {
    if(hasImageTag(options.option_a)) {
      return true;
    }
    if(hasImageTag(options.option_b)) {
      return true;
    }
    if(hasImageTag(options.option_c)) {
      return true;
    }
    if(hasImageTag(options.option_d)) {
      return true;
    }
    if(hasImageTag(options.option_e)) {
      return true;
    }
    return false;
    
  }
  
  function hasImageTag(content) {
    return !!content.match(/\<(img|svg)/);
  }
    
  
  function onChangeOption(option, text) {
    const data = { ...options, [option]:text };
    setOptions(data);
    var isEdited = checkIfEdited(data);
    setIsEdited(isEdited);
    var saveImageRequired = checkSaveImageRequired(data);
    
    if(saveImageRequired && (isEdited || !question.options_image)) {
      setSaveImageRequired(true);
    } else {
      setSaveImageRequired(false);
    }
  }
  
  const fixSvgInContent = (content) => {
    let replacedContent;
    content = content.trim().replaceAll("\n", "<br />")
    replacedContent = reactStringReplace(content, /(\<svg src\=['"].*?['"] \/\>)/, (match, i) => {
      let filePath = match.match(/\<svg src\=['"](.*?)['"] \/\>/).pop()
      const fileName = filePath.split('/').pop();
      if(svg_store.hasOwnProperty(fileName)) {
        return svg_store[fileName];
      } else {
        return match;
      }
    });
    return replacedContent.join('')
  }
  
  function getHtml() {
    return <ol type='A'>
      { options.option_a  && <li><div dangerouslySetInnerHTML={{__html: fixSvgInContent(options.option_a.replace(/(?:\r\n|\r|\n)/g, '<br>'))}} ></div></li>}
      { options.option_b  && <li><div dangerouslySetInnerHTML={{__html: fixSvgInContent(options.option_b.replace(/(?:\r\n|\r|\n)/g, '<br>'))}} ></div></li>}
      { options.option_c  && <li><div dangerouslySetInnerHTML={{__html: fixSvgInContent(options.option_c.replace(/(?:\r\n|\r|\n)/g, '<br>'))}} ></div></li>}
      { options.option_d  && <li><div dangerouslySetInnerHTML={{__html: fixSvgInContent(options.option_d.replace(/(?:\r\n|\r|\n)/g, '<br>'))}} ></div></li>}
      { options.option_e  && <li><div dangerouslySetInnerHTML={{__html: fixSvgInContent(options.option_e.replace(/(?:\r\n|\r|\n)/g, '<br>'))}} ></div></li>}
    </ol>
  }
  
  return (
    <div className='seg'>
      <div className={classNames('seg__left')}>
        <div className={classNames('seg__left__btns')}>
          <button onClick={() => setCurrentView('text')} disabled={ (currentView == 'text' || isSaving) ? true : false}>View as Text</button>
          <button onClick={() => setCurrentView('html')} disabled={ (currentView == 'html' || isSaving) ? true : false}>View as HTML</button>
          <button onClick={() => setCurrentView('image')} disabled={ (currentView == 'image' || !question.options_image ) ? true : false}>View as Image</button>
        </div>
        <div className={classNames('seg__left__view', currentView=='text' ? 'show' : 'hide')}>
          <div className={classNames('seg__left__view__option')}>
            <span>A</span>
            <textarea
              value={ options.option_a }
              onChange={ (e) => onChangeOption('option_a', e.target.value) }
            />
          </div>
          <div className={classNames('seg__left__view__option')}>
            <span>B</span>
            <textarea
              value={ options.option_b }
              onChange={ (e) => onChangeOption('option_b', e.target.value) }
            />
          </div>
          <div className={classNames('seg__left__view__option')}>
            <span>C</span>
            <textarea
              value={ options.option_c }
              onChange={ (e) => onChangeOption('option_c', e.target.value) }
            />
          </div>
          <div className={classNames('seg__left__view__option')}>
            <span>D</span>
            <textarea
              value={ options.option_d }
              onChange={ (e) => onChangeOption('option_d', e.target.value) }
            />
          </div>
          <div className={classNames('seg__left__view__option')}>
            <span>E</span>
            <textarea
              value={ options.option_e }
              onChange={ (e) => onChangeOption('option_e', e.target.value) }
            />
          </div>
        </div>
        <div 
          className={classNames('seg__left__view', currentView=='image' ? 'show' : 'hide')}
        >
          <img className={classNames('seg__left__view__image')} src={question.options_image ? `/api/media/images/${question.options_image}?rand=${imageRefreshRandomNumber}` : ``} alt='not available' />
        </div>
        <div 
          className={classNames('seg__left__view', currentView=='html' ? 'show' : 'hide')}
          >
          <div className={classNames('seg__left__view__display')}>
            <div className={classNames('seg__left__view__display__html')}
              ref={imageRef}
            >
              { currentView=='html' ? getHtml() : '' }
            </div>
          </div>
        </div>
      </div>
      <div 
        className={classNames('seg__right')}
      >
        { (currentView == 'text' || currentView == 'html') && <div>
            { currentView == 'text' && <button onClick={() => saveAsText()} disabled={ (isSaving || !isEdited) ? true : false}>Save as Text</button> }
            { currentView == 'html' && <button onClick={() => saveAsImage()} disabled={ (isSaving || !(isEdited || saveImageRequired)) ? true : false}>Save as Image</button> }
          </div>
        }
        { isEdited && <div>
            <button onClick={() => onCancel()} disabled={ isSaving ? true : false}>Cancel</button>
          </div>
        }
        { isSaving && <div>Saving <BeatLoader 
            color="#22AA22"
            loading={true}
            size={5}
            aria-label="Loading Spinner"
            data-testid="loader"
          /> Please wait!</div>
        }
      </div>
    </div>
  );
};

export default Options;
